/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    /*{
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    },*/
    // {
    //     id   : 'project',
    //     title: 'Project',
    //     type : 'basic',
    //     icon : 'heroicons_outline:desktop-computer',
    //     link : '/project'
    // }
    {
        id   : 'overview',
        title: 'Overview',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/overview'
    },
    {
        id   : 'health-status',
        title: 'Health Status',
        type : 'basic',
        icon : 'show_chart',
        link : '/health-status'
    },
    {
        id   : 'control',
        title: 'Control',
        type : 'basic',
        icon : 'graphic_eq',
        link : '/control'
    },
    {
        id   : 'alerts',
        title: 'Alerts',
        type : 'basic',
        icon : 'feather:alert-triangle',
        link : '/alerts'
    },
    {
        id   : 'settings',
        title: 'Settings',
        type : 'basic',
        icon : 'feather:settings',
        link : '/settings'
    }
];
export const compactNavigation: FuseNavigationItem[] = defaultNavigation;
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation;
